import React, { useState, Fragment } from 'react';

import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Stop from '@material-ui/icons/Stop';
import Typography from '@material-ui/core/Typography';

import Chat from './Chat';

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: theme.typography.h1.fontSize,
  },
  live: {
    color: theme.palette.secondary.main,
  },
  nowPlaying: {
    padding: theme.spacing(2),
    minHeight: 100
  }
}));

let stream;

const Live = ({ iceStats, messages, sendMessage }) => {
  const [isPlaying, setPlaying] = useState(false);
  const classes = useStyles();

  const handlePlay = () => {
    stream = new Audio('https://icecast.nerdsinthevoid.com/stream');
    stream.play();
    setPlaying(true);
  }

  const handlePause = () => {
    stream.pause();
    stream = null;
    setPlaying(false);
  }

  const PlayButton = () => (
    <Button onClick={handlePlay} color="primary">
      <PlayArrow className={classes.icon} />
    </Button>
  );

  const PauseButton = () => (
    <Button onClick={handlePause} color="primary">
      <Stop className={classes.icon} />
    </Button>
  );

  return (
    <div>
      <Typography variant="h3" className={classes.live} gutterBottom>LIVE</Typography>
      {iceStats.source && iceStats.source.listeners !== undefined && (
      <Typography gutterBottom>
        Happily playing to {iceStats.source.listeners} listeners
      </Typography>
      )}
      {isPlaying ? <PauseButton /> : <PlayButton />}
      <div className={classes.nowPlaying}>
        {iceStats.source && iceStats.source.title && (
          <Fragment>
            <Typography>Now Playing:</Typography>
            <Typography>{iceStats.source.title}</Typography>
          </Fragment>
        )}
      </div>
      <Chat messages={messages} handleSubmit={sendMessage} />
    </div>
  );
}

export default Live;