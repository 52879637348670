import React, { Fragment, useEffect, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as yup from 'yup';

import { Grid, Card, Button, Container } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
  },
  messagesContainer: {
    background: theme.palette.background.default,
    textAlign: 'left',
    height: 200,
    overflow: 'auto',
    border: `3px solid ${theme.palette.background.paper}`,
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
  },
  chatForm: {
    minHeight: 100,
  },
  label: {
    color: theme.palette.text.primary,
  },
  name: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  }
}));


const Message = ({ data }) => {
  const classes = useStyles();
  return (
    <div>
      <b className={classes.name}>[{data.name}]</b>
      <span>{data.message}</span>
    </div>
  );
};

const Chat = ({ messages, handleSubmit }) => {
  const classes = useStyles();
  const messageField = (el) => el && el.focus();
  const messagesContainer = useRef(null);
  useEffect(() => {
    messagesContainer.current.scrollTop = messagesContainer.current.scrollHeight;
  }, [messages]);

  return (
    <Fragment>
      <Card className={classes.card}>
        <Container className={classes.messagesContainer} ref={messagesContainer}>
          {messages.map((x) => <Message key={x.id} data={x} />)}
        </Container>
        <Formik
          initialValues={{ name: '', message: '' }}
          onSubmit={(values, { setSubmitting, setValues }) => {
            const handle = values.name || 'anon';
            handleSubmit({ topic: 'chat', name: handle, message: values.message });
            setValues({ name: handle, message: '' })
            setSubmitting(false);
          }}
          validationSchema={yup.object().shape({
            name: yup.string().max(20, 'Your name is too long.'),
            message: yup.string().max(180, 'You\'re going on a bit...').required('Speak up!'),
          })}
        >
          {({ isValid, isSubmitting }) => (
            <Form>
              <Grid container spacing={2} alignItems='center' className={classes.chatForm}>
                <Grid item xs={12} md={4}>
                  <Field
                    component={TextField}
                    name="name"
                    label="Name"
                    fullWidth
                    InputLabelProps={{ className: classes.label, shrink: true }}
                    autoComplete='off'
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    component={TextField}
                    name="message"
                    label="Message"
                    fullWidth
                    InputLabelProps={{ className: classes.label, shrink: true }}
                    inputRef={messageField}
                    autoComplete='off'
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    color="primary"
                    size="large"
                    fullWidth
                  >
                    Send
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Card>
    </Fragment>
  )
}

export default Chat;