import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#093'},
    secondary: red,
    background: {
        paper: '#222',
        default: '#000',
    },
    text: {
        primary: '#fff',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 16,
    h1: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 300,
        fontSize: "6rem",
        lineHeight: 1,
        letterSpacing: "-0.01562em",
    },
    h2: {
        fontFamily: '"VT323", "Helvetica", "Arial", sans-serif',
        fontWeight: 300,
        fontSize: "3rem",
        lineHeight: 1,
        letterSpacing: "-0.01562em"
    },
    h3: {
        fontFamily: '"VT323", "Helvetica", "Arial", sans-serif',
        fontSize: "2rem",
        letterSpacing: "-0.01562em"
    },
  },
});

export default theme;