import React, { useState, useEffect } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import Live from './components/Live';
import logo from './logo.png';

const useStyles = makeStyles((theme) => ({
  app: {
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
}));

const url = 'wss://nerdsinthevoid.com/ws'
const _ws_ = new WebSocket(url);

const App = () => {
  const [messages, addMessage] = useState([{ name: 'Welcome to the Void'}]);
  const [iceStats, setIceStats] = useState();
  const [isLive, setLive] = useState(false);
  const [ws, setWs] = useState(_ws_);
  const classes = useStyles();

  const sendMessage = (data) => { ws.send(JSON.stringify(data)); };

  ws.onclose = () => { setWs(new WebSocket(url)) };
  ws.onerror = (error) => { console.log(`WebSocket error: ${error}`) };

  useEffect(() => {
    ws.onmessage = (e) => {
      const data = JSON.parse(e.data);
      switch (data.topic) {
        case 'chat':
          addMessage((state) => ([...state, data]));
          return; 
        case 'icecast':
          const icecastData = data.message.icestats;
          setIceStats(icecastData);
          !icecastData.source ? setLive(false) : setLive(true);
          return;
        default:
          return;
      }
    }
  }, [iceStats, isLive, messages, ws]);

  return (
    <div className={classes.app}>
      <Box mx={2}><img src={logo} alt="nitv" /></Box>
      <Container maxWidth="md">
        {isLive
          ? <Live iceStats={iceStats} messages={messages} sendMessage={sendMessage} />
          : <Typography>Nowt happening in the Void.</Typography>
        }
      </Container>
    </div>
  );
}

export default App;
